import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Layout from "../components/Layout/Layout";
import LearningResourcesFilterColumn from "../components/LearningResourcesFilterColumn/LearningResourcesFilterColumn";
import LearningResourcesGrid from "../components/LearningResourcesGrid/LearningResourcesGrid";
import LearningResourcesHero from "../components/LearningResourcesHero/LearningResourcesHero";
import LearningResourcesSearchBar from "../components/LearningResourcesSearchBar/LearningResourcesSearchBar";
import { SET_SEARCH_TERM } from "../state/constants/learningResources";
import { theme } from "../utils/global-styles/theme";

const LearningResources = ({
  data,
  pageContext: { locale },
  location: { pathname, href: currentUrl },
}) => {
  const [loading, setLoading] = useState(false);
  const learningResourcesPageData = data?.pages?.nodes;
  const learningResources = data?.resources?.nodes;

  const DesktopinnerWrapper = styled.div`
    display: block;
    width: 30%;
    height: 100vh;
    border-right: 1px solid #b4b4b4;
  `;

  const FiltersAndGridWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 50px;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      flex-direction: column;
    }
  `;

  const OpenFiltersButton = styled.div`
    cursor: pointer;
    width: 80%;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    display: block;
    margin: 0 auto;
    font-family: ${theme.fontFaces.paragraphs};
    margin-top: 30px;

    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      display: none;
    }
  `;

  const MobileFilterColumnWrapper = styled.div`
    display: block;
    width: 100vw;
    position: fixed;
    left: 0;
    background-color: ${theme.colors.background};
    box-shadow: 0 0 4px 2px rgb(0 0 0 / 17%);
    z-index: 6;
    right: 0;
    font-family: ${theme.fontFaces.paragraphs};
    color: #323232;
    top: 60px;
    overflow-y: scroll;
    bottom: 0;
    padding-top: 70px;
  `;

  const CloseFiltersButton = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    color: white;
    display: block;
    margin: 0 auto;
    font-family: ${theme.fontFaces.paragraphs};
    margin-top: 30px;

    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      display: none;
    }
  `;

  const MobileLineSeparator = styled.hr`
    margin-top: 27px;
    margin-bottom: 27px;
    display: none;
    width: 80%;
    float: right;
    margin-right: 20px;
    border-top: 1px solid #b4b4b4;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      display: block;
      float: none;
      margin: 0 auto;
      margin-top: 30px;
    }
  `;

  const DesktopAndTabletWrapper = styled.div`
    font-family: ${theme.fontFaces.paragraphs};
    display: block;
    margin: 0 auto;
    min-height: 600px;
    margin-bottom: 50px;
    margin-top: 61px;
    max-width: ${theme.breakpoints.maxContent};
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      display: none;
    }

    @media (min-width: ${theme.breakpoints.helper750}) and (max-width: ${theme
        .breakpoints.desktopUp}) {
      margin-top: 50px;
    }
  `;

  const MobileWrapper = styled.div`
    display: none;
    margin-bottom: 50px;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      display: block;
    }
  `;

  const LineSeparator = styled.hr`
    margin-top: 27px;
    margin-bottom: 27px;
    display: block;
    width: 80%;
    float: right;
    margin-right: 20px;
    border-top: 1px solid #b4b4b4;

    @media only screen and (max-width: ${theme.breakpoints.helper750}) {
      float: none;
      margin: 0 auto;
      margin-top: 30px;
    }
  `;

  const [selectedCategories, setSelectedCategories] = useState([]);

  const toggleCategory = category => {
    setSelectedCategories(prevCategories =>
      prevCategories.includes(category)
        ? prevCategories.filter(cat => cat !== category)
        : [...prevCategories, category]
    );
  };

  const [openMobileFilters, setOpenMobileFilters] = useState(false);

  const toggleFilterModal = () => {
    setOpenMobileFilters(!openMobileFilters);
  };

  const dispatch = useDispatch();

  const toggleAllCategories = () => {
    const allCategories = Array.from(
      new Set(learningResources.map(resource => resource.resourceCategory))
    );

    if (
      (selectedCategories.length > 0 &&
        selectedCategories.length < allCategories.length) ||
      selectedCategories.length == 0
    ) {
      setSelectedCategories(allCategories);
    } else {
      setSelectedCategories([]);
    }
  };

  return (
    <Layout locale={locale} loading={loading} currentPath={pathname}>
      <LearningResourcesHero
        heading={learningResourcesPageData[0].heading}
        shortDescription={learningResourcesPageData[0].shortDescription}
        image={learningResourcesPageData[0].heroImage.image.fluid}
        cardHeader={learningResourcesPageData[0].highlightedHeader}
        cardSubheader={learningResourcesPageData[0].highlightedSubheader}
        popularLearningResources={
          learningResourcesPageData[0].highlightedContentModules
        }
      />
      {openMobileFilters && (
        <Global
          styles={css`
            body {
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          `}
        />
      )}
      <DesktopAndTabletWrapper fullDeviceWidth>
        <FiltersAndGridWrapper>
          <DesktopinnerWrapper>
            <LearningResourcesSearchBar />
            <LineSeparator />
            <LearningResourcesFilterColumn
              selectedCategories={selectedCategories}
              learningResources={learningResources}
              toggleCategory={toggleCategory}
              toggleAllCategories={toggleAllCategories}
            />
          </DesktopinnerWrapper>
          <LearningResourcesGrid
            learningResources={learningResources}
            selectedCategories={selectedCategories}
            setLoading={setLoading}
          />
        </FiltersAndGridWrapper>
      </DesktopAndTabletWrapper>
      <MobileWrapper>
        <LearningResourcesSearchBar />
        <OpenFiltersButton
          className="primary-bg"
          onClick={() => toggleFilterModal()}
        >
          Filters
        </OpenFiltersButton>
        <MobileLineSeparator />
        <LearningResourcesGrid
          learningResources={learningResources}
          selectedCategories={selectedCategories}
          setLoading={setLoading}
        />
        {openMobileFilters && (
          <MobileFilterColumnWrapper>
            <LearningResourcesFilterColumn
              selectedCategories={selectedCategories}
              learningResources={learningResources}
              toggleCategory={toggleCategory}
              toggleAllCategories={toggleAllCategories}
            />
            <CloseFiltersButton
              className="primary-bg"
              onClick={() => {
                setOpenMobileFilters(false);
                dispatch({ type: SET_SEARCH_TERM, payload: "" });
              }}
            >
              Search
            </CloseFiltersButton>
          </MobileFilterColumnWrapper>
        )}
      </MobileWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    pages: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "learning-resources-landing" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        heading
        shortDescription
        availableLocales
        highlightedHeader
        highlightedSubheader
        heroImage {
          image {
            fluid {
              srcSet
            }
          }
        }
        highlightedContentModules {
          ... on ContentfulLearningResource {
            title
            durationRange
            duration
            shortDescription
            resourceImage {
              fluid {
                srcSet
              }
            }
            resourceCategory
          }
        }
      }
    }
    resources: allContentfulLearningResource(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        resourceImage {
          fluid {
            src
          }
        }
        title
        shortDescription
        duration
        durationRange
        resourceCategory
        resourceId
      }
    }
  }
`;

export default LearningResources;
