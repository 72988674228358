import React from "react";
import LearningResourcesSearchBar from "../LearningResourcesSearchBar/LearningResourcesSearchBar";
import SquareIcon from "../FilterColumn/SquareIcon";
import Checkbox from "../FilterColumn/Checkbox";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";
import { css } from "@emotion/core";
import Info from "../FilterColumn/Info";
import ReactTooltip from "react-tooltip";

const FiltersContainer = styled.div`
  border-right: 1px solid #b4b4b4;
  border-left: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
  display: block;
  width: 80%;
  float: right;
  margin-right: 20px;
  font-weight: bold;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0px;
  }
`;

const FiltersTitle = styled.div`
  font-size: 15px;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
  padding-left: 10px;
  cursor: pointer;
`;

const SquareIconWrapper = styled.div`
  margin-right: 10px;
  left: 10px;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 15px;
  left: 30px;
  top: 4px;
  width: 86%;
`;

const FilterList = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Category = styled.div`
  display: flex;
  margin-bottom: 15px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
`;

const FiltersWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
  }
`;
const Label = styled.div`
  display: flex;
  padding: 1em;
  font-size: 16px;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;
const Infowrapper = styled.a`
  margin-left: 8px;
  cursor: pointer;
`;

const LearningResourcesFilterColumn = ({
  selectedCategories,
  learningResources,
  toggleCategory,
}) => {
  const getUniqueResourceCategories = resources => {
    // Create a Set to store unique categories
    const uniqueCategories = new Set();

    // Iterate over each resource and add its category to the Set
    resources.forEach(resource => {
      uniqueCategories.add(resource.resourceCategory);
    });

    // Convert the Set to an array and return it
    let categories = Array.from(uniqueCategories);
    return categories.map((category, index) => {
      return (
        <Category
          key={index}
          onClick={() => {
            toggleCategory(category);
          }}
        >
          <Checkbox checked={selectedCategories.includes(category)} />
          {category}
        </Category>
      );
    });
  };

  const uniqueCategories = resources => {
    // Create a Set to store unique categories
    const uniqueCategories = new Set();

    // Iterate over each resource and add its category to the Set
    resources.forEach(resource => {
      uniqueCategories.add(resource.resourceCategory);
    });

    // Convert the Set to an array and return it
    let categories = Array.from(uniqueCategories);
    return categories;
  };

  return (
    <FiltersWrapper>
      <FiltersContainer>
        <FiltersTitle
          onClick={() => {
            toggleAllCategories();
          }}
        >
          <SquareIconWrapper>
            <SquareIcon
              color={
                selectedCategories.length ==
                uniqueCategories(learningResources).length
                  ? theme.colors.main
                  : theme.colors.background
              }
            />
          </SquareIconWrapper>
          <Title>Resource Category</Title>
        </FiltersTitle>
        <FilterList>
          {getUniqueResourceCategories(learningResources)}
        </FilterList>
      </FiltersContainer>
      <br />
      <FiltersContainer
        css={css`
          margin-top: 2em;
          border-top: 1px solid #b4b4b4;
        `}
      >
        <ReactTooltip
          id="main"
          place="top"
          type="dark"
          effect="float"
          multiline={true}
        />
        <Label>
          Facing a technical issue?
          <Infowrapper
            data-for="main"
            data-tip={"Send an email to coduk@acumen.org for help."}
            data-iscapture="true"
          >
            <Info />
          </Infowrapper>
        </Label>
      </FiltersContainer>
    </FiltersWrapper>
  );
};

export default LearningResourcesFilterColumn;
